export enum SearchOperator {
  NOT_USED = "NOT_USED",
  CONTAINS = "CONTAINS",
  STARTS_WITH = "STARTS_WITH",
  ENDS_WITH = "ENDS_WITH",
  EQUALS="EQUALS",
  IN="IN",
  IS_EMPTY = "IS_EMPTY",
  IS_NOT_EMPTY = "IS_NOT_EMPTY",
  BETWEEN = "BETWEEN",
  GREATER_THAN = "GREATER_THAN",
  LESS_THAN = "LESS_THAN"
}
